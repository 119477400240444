@font-face {
    font-family: 'Lexend-Light';
    src:  local("Lexend-Light"),
    url(../assets/fonts/Lexend-Light.ttf) format("truetype");
}
@font-face {
    font-family: 'Lexend-Regular';
    src:  local("Lexend-Regular"),
    url(../assets/fonts/Lexend-Regular.ttf) format("truetype");
}
@font-face {
    font-family: 'Lexend-Medium';
    src:  local("Lexend-Medium"),
    url(../assets/fonts/Lexend-Medium.ttf) format("truetype");
}
@font-face {
    font-family: 'Lexend-SemiBold';
    src:  local("Lexend-SemiBold"),
    url(../assets/fonts/Lexend-SemiBold.ttf) format("truetype");
}
@font-face {
    font-family: 'Lexend-Bold';
    src:  local("Lexend-Bold"),
    url(../assets/fonts/Lexend-Bold.ttf) format("truetype");
}